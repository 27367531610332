import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import pic from "./images/truck-logistics-operation-dusk.jpg";
import logo from "./images/1 (1).svg"

const SuperAdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        email,
        password,
        role: "Super Admin", // Specify role as Super Admin
      });
console.log('response.data',response.data.role)
      setSuccess(response.data.message);
      localStorage.setItem("vahan-token", response.data.token);
      localStorage.setItem("vahan-role", response.data.role);
      // Redirect to the dashboard after successful login
      navigate("/superadmin-dashboard");
    } catch (error) {
      setError(error.response?.data?.message || "Something went wrong");
      setLoading(false)
    }
  };

  return (
    <div className=" w-full bg-[#f3f4f2]">
    <div className="w-full  flex items-center h-[90vh]">
    <div className="w-full sm:w-1/2 h-full flex flex-col items-center justify-center p-5">
    
    <img src={logo} className="max-w-[150px]" alt="logo"/>

      <div className="w-full mt-9">
      <form onSubmit={handleSubmit} className="max-w-[720px] mx-auto ">
        <h2 className="text-3xl  font-semibold mb-5">Super Admin Login</h2>
        <label className="block font-semibold text-gray-600">
              Company Email
            </label>

        <input
          type="email"
          placeholder="Super Admin Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="block border p-2 mt-2 mb-4 w-full  text-base font-medium"
        />
        <label className="block font-semibold text-gray-600">
              Password
            </label>

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="block border p-2 mt-2 mb-4 w-full   text-base font-medium"
        />
        {/* <span
              className=" block w-full cursor-pointer mt-2 mb-2 text-sm font-semibold text-gray-400 text-right "
            >
              forgot password ?
            </span> */}

        <button
          type="submit"
          disabled={loading}
          className="bg-[#26a7c8] w-full  p-2 text-white rounded hover:bg-[#1f87a1] transition"
        >
          {loading ? "Loading..." : "Login As Super Admin"}
        </button>

        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
      </form>
      </div>
    </div>
    <div className="hidden sm:block  w-1/2    h-full rounded-lg">
          
    <img
      src={pic}
      className=" bg-cover bg-center w-full h-full "
      alt="logo"
    />
  </div>
  </div>
  </div>
  );
};

export default SuperAdminLogin;
