import React, { useState } from 'react';
import { FaComment, FaSearch, FaEye } from 'react-icons/fa';
import CommentModal from './Modal/CommentModal';

const GuardTruckTable = ({ truckLoadings, onViewDetails, currentPage, totalPages, onPageChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTruckLoadingId, setSelectedTruckLoadingId] = useState(null);

    const openModal = (id) => {
        setSelectedTruckLoadingId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedTruckLoadingId(null);
        setIsModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredLoadings = truckLoadings.filter(loading =>
        loading.vehicleNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="w-full">
            {/* Search Input */}
            <div className="flex items-center border rounded-full overflow-hidden w-full md:w-64 bg-white mt-2 md:mt-0">
                <input
                    type="text"
                    placeholder="Enter Vehicle Number"
                    className="px-4 py-1 border-none outline-none w-full h-10"
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <div className="px-4">
                    <FaSearch />
                </div>
            </div>

            {/* Table */}
            <div className="overflow-x-auto mt-4">
                {filteredLoadings.length === 0 ? (
                    <p className="text-center text-gray-500">No truck loadings found.</p>
                ) : (
                    <table className="min-w-full divide-y divide-gray-200">
  <thead>
    <tr>
      <th className="py-3 px-6 text-left uppercase">ID</th>
      <th className="py-3 px-6 text-left uppercase">Created By</th>
      <th className="py-3 px-6 text-left uppercase">Created At</th>
      <th className="py-3 px-6 text-left uppercase">Role</th>
      <th className="py-3 px-6 text-left uppercase">Transporter Name</th>
      <th className="py-3 px-6 text-left uppercase">Vehicle No</th>
      <th className="py-3 px-6 text-left uppercase">Driver Name</th>
      <th className="py-3 px-6 text-left uppercase">Material Weight</th>
      <th className="py-3 px-6 text-left uppercase">Comment</th>
      <th className="py-3 px-6 text-left uppercase">Reason</th>
      <th className="py-3 px-6 text-left uppercase">Commented By</th>
      <th className="py-3 px-6 text-left uppercase">Status</th>
      <th className="py-3 px-6 text-left uppercase">Verified By</th>
      <th className="py-3 px-6 text-left uppercase">Verified Status</th>
      <th className="py-3 px-6 text-left uppercase">Verified Date</th>

      <th className="px-3 py-6 uppercase">Action</th>
    </tr>
  </thead>
  <tbody className="bg-white divide-y divide-gray-200">
    {filteredLoadings.map((loading) => (
      <tr key={loading._id} className="border-b hover:bg-gray-50">
        <td className="py-3 px-6">{loading._id}</td>
        <td className="py-3 px-6">{loading.createdBy?.username}</td>
        <td className="py-3 px-6">
          {new Date(loading.createdAt).toLocaleDateString()} {new Date(loading.createdAt).toLocaleTimeString()}
        </td>
        <td className="py-3 px-6">{loading.createdBy?.role}</td>
        <td className="py-3 px-6">{loading.transporterName || "N/A"}</td>
        <td className="py-3 px-6">{loading.vehicleNumber}</td>
        <td className="py-3 px-6">{loading.driverName}</td>
        <td className="py-3 px-6">{loading.netMaterialWeight || "N/A"}</td>
        <td className="py-3 px-6">{loading.comment || "N/A"}</td>
        <td className="py-3 px-6">{loading.reason || "N/A"}</td>
        <td className="py-3 px-6">{loading?.CommentedBy?.username || "N/A"}</td>
        <td className="py-3 px-6">{loading.status}</td>
        {/* Verified By */}
        <td className="py-3 px-6">
          {loading.verified?.map((verification, index) => (
            <div key={index}>
              {verification.verifiedBy?.username || "N/A"}
            </div>
          ))}
        </td>
        {/* Verified Status */}
        <td className="py-3 px-6">
          {loading.verified?.map((verification, index) => (
            <div key={index}>
              {verification.verifiedStatus || "N/A"}
            </div>
          ))}
        </td>
        {/* Verified Date */}
        <td className="py-3 px-6">
  {loading.verified?.map((verification, index) => (
    <div key={index}>
      {verification.verifiedAt
        ? new Date(verification.verifiedAt).toLocaleString("en-US", {
            hour: "numeric", // Display hour (e.g., 4)
            minute: "numeric", // Display minute (e.g., 30)
            hour12: true, // Use 12-hour format (e.g., 4:30 pm)
            weekday: "long", // Display full weekday (e.g., Saturday)
            month: "long", // Display full month (e.g., February)
            day: "numeric", // Display day (e.g., 1)
            year: "numeric", // Display year (e.g., 2025)
          })
        : "N/A"}
    </div>
  ))}
</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <div className="flex justify-between gap-3">
            <FaEye
              className="text-blue-600 hover:text-blue-800 cursor-pointer"
              onClick={() => onViewDetails(loading._id)}
              title="View Details"
            />
            <FaComment className="cursor-pointer text-black" onClick={() => openModal(loading._id)} />
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>
                )}
            </div>

            {/* Pagination Controls */}
            {filteredLoadings.length > 0 && (
                <div className="flex justify-between mt-4 sticky bottom-0 bg-white py-2">
                    <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                        </button>
                </div>
            )}

            <CommentModal isOpen={isModalOpen} onClose={closeModal} truckLoadingId={selectedTruckLoadingId} />
        </div>
    );
};

export default GuardTruckTable;
