import React, { useEffect, useState,useRef  } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../Common/Header';
import { decryptData } from '../crypto-js/crypto';
import TruckDeta from './TruckDeta';

const TruckDetails = () => {
  const { id } = useParams(); // Get the truck ID from the URL
  const [truckDetails, setTruckDetails] = useState(null);
  const [error, setError] = useState('');
  const storedRole = localStorage.getItem("pair");
  const x = decryptData(storedRole);
  console.log('x',x)
  const x1 = localStorage.getItem('twist');
const decryptdata = decryptData(x1);
const x2 = localStorage.getItem('tang');
const decryptdata1 = decryptData(x2);
const x3 = localStorage.getItem('bhoot');
const decryptdata2 = decryptData(x3);
const printableRef = useRef(null);
  // Fetch truck details
  const fetchTruckDetails = async () => {
    const token = localStorage.getItem('vahan-token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/truck/getTruckDetailsById/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTruckDetails(response.data.data); // Set truck details
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching truck details');
    }
  };
  const handlePrint = () => {
    const printContent = printableRef.current;
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Truck Details</title>');
    printWindow.document.write(
      '<style>@media print { body { font-family: Arial, sans-serif; margin: 0; } .printable { width: 210mm; height: 297mm; margin: auto; padding: 20mm; } }</style>'
    );
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };
  useEffect(() => {
    fetchTruckDetails();
  }, [id]);

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }
  // const formattedTime = truckDetails.truckReachTime
  // ? new Intl.DateTimeFormat("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //   }).format(new Date(truckDetails.truckReachTime))
  // : null;
  return (
    <div className="p-4 w-full">
             <Header role={x} companyname={decryptdata} username={decryptdata1} coins={decryptdata2}/>
             <button
        onClick={handlePrint}
        className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 mb-4"
      >
        Print Truck Details
      </button>
      <div ref={printableRef}>
      {truckDetails ? (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-3xl font-bold mb-4">Truck Details</h2>

          {/* Truck Information */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <p><strong>Vehicle Number:</strong> {truckDetails.vehicleNumber}</p>
              <p><strong>Driver Name:</strong> {truckDetails.driverName}</p>
              <p><strong>Transporter Name:</strong> {truckDetails.transporterName}</p>
              <p><strong>Material Name:</strong> {truckDetails.materialName}</p>
              <p><strong>Receiver Party Name:</strong> {truckDetails.receiverPartyName}</p>
              <p><strong>Driver Contact Number:</strong> {truckDetails.driverContactNumber}</p>
              <p><strong>Loading Site:</strong> {truckDetails.loadingSite}</p>
              <p><strong>Loading Date:</strong> {new Date(truckDetails.createdAt).toLocaleString()}</p>
              <p><strong>Freight:</strong> {truckDetails.freight}</p>
              <p><strong>Status:</strong> {truckDetails.status}</p>            </div>
            <div>
              <p><strong>Gross Weight:</strong> {truckDetails.grossWeight} kg</p>
              <p><strong>Tare Weight:</strong> {truckDetails.tareWeight} kg</p>
              <p><strong>Net Material Weight:</strong> {truckDetails.netMaterialWeight} kg</p>
              <p><strong>Shortage of Materials:</strong> {truckDetails.shortageOfMaterials}</p>
              <p><strong>TDS:</strong> {truckDetails.tds}</p>
              <p><strong>Advanced:</strong> {truckDetails.advanced}</p>
              <p><strong>Loader Name:</strong> {truckDetails.loaderName}</p>
              <p><strong>Loader Mobile Number:</strong> {truckDetails.loaderMobileNumber}</p>
              <p><strong>Unloading Site:</strong> {truckDetails.unloadingSite}</p>
              <p><strong>Unloading Date:</strong>
              {truckDetails.updatedAt ? new Date(truckDetails.updatedAt).toLocaleString() :" Truck Still running "}
               </p>
               <p>
  <strong>Received Weight:</strong> 
  {truckDetails.receivedWeight || truckDetails.receivedWeight === 0
    ? truckDetails.receivedWeight
    : "Truck Is Still Running"}
</p>
              <p>
  <strong>Truck Reach Time:</strong>{" "}
  {truckDetails.truckReachTime
    ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short", // Displays abbreviated month (e.g., Jan, Feb)
        day: "2-digit", // Displays the day with two digits
        hour: "numeric",
        minute: "numeric",
        hour12: true, // Ensures AM/PM format
        timeZone: "UTC", // Keeps time as UTC
      }).format(new Date(truckDetails.truckReachTime))
    : "Truck Is Still Running"}
</p>

            </div>
          </div>

          {/* Company and CreatedBy Information */}
          <div className="mb-6">
            <h3 className="text-2xl font-semibold">Created By & Company</h3>
            <p><strong>Created By:</strong> {truckDetails.createdBy.email}</p>
            <p><strong>Company Name:</strong> {truckDetails.company.name}</p>
          </div>
   {/* Additional Truck Information */}
   <div className="mt-6">
            <h3 className="text-2xl font-semibold">Additional Information</h3>
            <p><strong>Fuel (in Liters):</strong> {truckDetails.fuel.inLiters}</p>
            <p><strong>Fuel Price:</strong> {truckDetails.fuel.price}</p>
            <p><strong>Pump Name:</strong> {truckDetails.fuel.pumpName}</p>
            <p><strong>Stations Challan Number:</strong> {truckDetails.fuel.stationsChallanNumber}</p>
          </div>
          <div className="columns-2 md:columns-3 gap-4">
  {truckDetails.qrCodes.map((qrCode, index) => (
    <div
      key={index}
      className="mb-4 break-inside-avoid border p-2 rounded shadow"
    >
      <p className="text-center">QRCODES:{qrCode.qrCode}</p>
      <p className="text-center">Status:{qrCode.status}</p>
    </div>
  ))}
</div>





          {/* Images Section */}
          <div className="mt-6">
            <h3 className="text-2xl font-semibold mb-2">Images</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {truckDetails.images.gpsImeiPic.length > 0 && truckDetails.images.gpsImeiPic[0] && (
                <div className="border rounded-lg overflow-hidden shadow-md">
                  <h4 className="p-2 bg-gray-100 font-medium">GPS IMEI Picture</h4>
                  <img
                    src={truckDetails.images.gpsImeiPic[0]}
                    alt="GPS IMEI"
                    className="w-full h-auto"
                  />
                </div>
              )}
{truckDetails.images.vehicleNumberPlate.length > 0 && truckDetails.images.vehicleNumberPlate[0] && (
                <div className="border rounded-lg overflow-hidden shadow-md">
                  <h4 className="p-2 bg-gray-100 font-medium">Vehicle Number Plate</h4>
                  <img
                    src={truckDetails.images.vehicleNumberPlate[0]}
                    alt="GPS IMEI"
                    className="w-full h-auto"
                  />
                </div>
              )}
              {truckDetails.images.driverPic.length > 0 && truckDetails.images.driverPic[0] && (
                <div className="border rounded-lg overflow-hidden shadow-md">
                  <h4 className="p-2 bg-gray-100 font-medium">Driver Picture</h4>
                  <img
                    src={truckDetails.images.driverPic[0]}
                    alt="Driver"
                    className="w-full h-auto"
                  />
                </div>
              )}

              {truckDetails.images.vehicleImages.length > 0 && (
                <div className="border rounded-lg overflow-hidden shadow-md">
                  <h4 className="p-2 bg-gray-100 font-medium">Vehicle Images</h4>
                  <div className="grid grid-cols-1 gap-2 p-2">
                    {truckDetails.images.vehicleImages.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Vehicle ${index + 1}`}
                        className="w-full h-auto"
                      />
                    ))}
                  </div>
                </div>
              )}

              {truckDetails.images.sealingImages.length > 0 && (
                <div className="border rounded-lg overflow-hidden shadow-md">
                  <h4 className="p-2 bg-gray-100 font-medium">Sealing Images</h4>
                  <div className="grid grid-cols-1 gap-2 p-2">
                    {truckDetails.images.sealingImages.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Sealing ${index + 1}`}
                        className="w-full h-auto"
                      />
                    ))}
                    
                  </div>
                </div>
                
              )}
            </div>
          </div>

      
        </div>
      ) : (
        <p>Loading...</p>
      )}
      </div>
    </div>
  );
};

export default TruckDetails;
