import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import io from 'socket.io-client';

const containerStyle = {
  width: '100vw',
  height: '100vh',
};

const center = {
  lat: 37.7749,
  lng: -122.4194,
};

const Test = () => {
  const [driverPosition, setDriverPosition] = useState(center);
  const [route, setRoute] = useState([center]);
  const [activeMarker, setActiveMarker] = useState(null);
  
  useEffect(() => {
    const socket = io('http://localhost:4000'); // Change to your backend URL

    socket.on('driverLocation', (location) => {
      setDriverPosition(location);
      setRoute((prevRoute) => [...prevRoute, location]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyB_nv6-ioltotQ2mrTwmEdkRWVWTHwrLMo">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12}>
        <Marker 
          position={driverPosition} 
          onClick={() => setActiveMarker('driver')}
        >
          {activeMarker === 'driver' && (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <h4>Driver Location</h4>
                <p>Latitude: {driverPosition.lat.toFixed(5)}</p>
                <p>Longitude: {driverPosition.lng.toFixed(5)}</p>
              </div>
            </InfoWindow>
          )}
        </Marker>
        
        {/* Polyline to show the route */}
        <Polyline 
          path={route} 
          options={{ 
            strokeColor: '#FF0000', 
            strokeOpacity: 0.75, 
            strokeWeight: 2 
          }} 
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default Test;
