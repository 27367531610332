import React, { useState, useEffect } from "react";
import axios from "axios";
import Test from "./Test"; // Assuming you have the Test component

const EditTruckLoadingForm = ({ isOpen, onClose, loadingId, onRefresh }) => {
  const [activeTab, setActiveTab] = useState("loading");
  const [formData, setFormData] = useState({
      transporterName: '',
      materialName: '',
      receiverPartyName: '',
      vehicleNumber: '',
      gpsImeiNumber: '',
      driverName: '',
      driverContactNumber: '',
      loadingSite: '',
      loadingDateAndTime: '',
      loaderName: '',
      challanRoyaltyNumber: '',
      doNumber: '',
      freight: '',
      qualityOfMaterials: '',
      tpNumber: '',
      grossWeight: '',
      tareWeight: '',
      netMaterialWeight: '',
      loaderMobileNumber: '',
      unloadingSite: '',
      unloadingDateAndTime: '',
      receivedWeight: '',
      shortageOfMaterials: '',
      tds: '',
      advanced: '',
      fuel: {
        inLiters: '',
        price: '',
        pumpName: '',
        stationsChallanNumber: ''
      },
      sealingDate: '',
      sealingPoint: '',
      sealingColour: '',
      totalSealQuantity: '',
      images: {
        gpsImeiPic: '',
        vehicleNumberPlate: '',
        driverPic: '',
        sealingImages: [],
        vehicleImages: []
      },
      company: '',
      createdBy: '',
    });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState({});
  
  useEffect(() => {
    if (isOpen && loadingId) {
      fetchLoadingDetails();
    }
  }, [isOpen, loadingId]);

  const fetchLoadingDetails = async () => {
    const token = localStorage.getItem('vahan-token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/permission/getUserById/${loadingId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const loadingData = response.data;
      setLoadingDetails(loadingData);
      setFormData((prevData) => ({
        ...prevData,
        ...loadingData,
        images: loadingData.images || {}, // Ensure existing images are populated
      }));
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching loading details');
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImagesChange = async (e, field) => {
    const { files } = e.target;
    const fileArray = Array.from(files);
    const uploadedImages = [];

    for (const file of fileArray) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'testpreset'); // Replace with your Cloudinary upload preset

      try {
        const response = await axios.post(`https://api.cloudinary.com/v1_1/dlgyhmuxb/image/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        uploadedImages.push(response.data.secure_url); // Save the secure URL of the uploaded image
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      images: {
        ...prevData.images,
        [field]: [...prevData.images[field] || [], ...uploadedImages],
      },
    }));
  };

  const handleSave = async () => {
    const token = localStorage.getItem('vahan-token');
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/permission/updateUser1/${loadingId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onRefresh(); // Refresh the parent component data
      resetForm(); // Reset the form after save
      onClose(); // Close the modal
    } catch (err) {
      setError(err.response?.data?.message || 'Error updating loading details');
    }
  };

  const resetForm = () => {
    setFormData({
        transporterName: '',
        materialName: '',
        receiverPartyName: '',
        vehicleNumber: '',
        gpsImeiNumber: '',
        driverName: '',
        driverContactNumber: '',
        loadingSite: '',
        loaderName: '',
        challanRoyaltyNumber: '',
        doNumber: '',
        freight: '',
        qualityOfMaterials: '',
        tpNumber: '',
        grossWeight: '',
        tareWeight: '',
        netMaterialWeight: '',
        loaderMobileNumber: '',
        unloadingSite: '',
        receivedWeight: '',
        shortageOfMaterials: '',
        tds: '',
        advanced: '',
        fuel: {
          inLiters: '',
          price: '',
          pumpName: '',
          stationsChallanNumber: ''
        },
        sealingDate: '',
        sealingPoint: '',
        sealingColour: '',
        totalSealQuantity: '',
        images: {
          gpsImeiPic: '',
          vehicleNumberPlate: '',
          driverPic: '',
          sealingImages: [],
          vehicleImages: []
        },
        company: '',
        createdBy: '',
      });
  };

  const handleQrCodeScan = (data) => {
    console.log("QR Code Scanned:", data);
  };

  return (
    <div className="mx-auto p-4 w-full">
      {error && <div className="text-red-500">{error}</div>}

      {/* Tabs */}
      <div className="m-4 flex flex-wrap sm:flex-wrap lg:flex-nowrap">
        {["loading"].map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`tab py-2 px-4 m-2 rounded-lg transition-colors duration-200 ease-in-out w-full sm:w-[160px] ${
              activeTab === tab
                ? "bg-indigo-500 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-blue-100"
            }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)} Details
          </button>
        ))}
      </div>

      <form onSubmit={(e) => e.preventDefault()} className="mt-4">
        {activeTab === 'loading' && (
             <div className=' justify-center lg:flex-wrap sm:flex sm:flex-wrap'>
             <input type="text" name="transporterName" placeholder="Transporter Name" value={formData.transporterName} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="materialName" placeholder="Material Name" value={formData.materialName} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="receiverPartyName" placeholder="Receiver Party Name" value={formData.receiverPartyName} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="vehicleNumber" placeholder="Vehicle Number" value={formData.vehicleNumber} onChange={handleChange} required className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="gpsImeiNumber" placeholder="GPS IMEI Number" value={formData.gpsImeiNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="driverName" placeholder="Driver Name" value={formData.driverName} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input 
   type="tel" 
   name="driverContactNumber" 
   placeholder="Driver Contact Number" 
   value={formData?.driverContactNumber} 
   onChange={handleChange} 
   className="mb-2 p-2 border rounded w-full" 
   minLength={10} 
   maxLength={15} // Optional: limit to a maximum length
 />
 
 {formData?.driverContactNumber?.length > 0 && formData?.driverContactNumber?.length < 10 && (
   <p className="text-red-500">Phone number must be at least 10 digits.</p>
 )}
             <input type="text" name="loadingSite" placeholder="Loading Site" value={formData.loadingSite} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="loaderName" placeholder="Loader Name" value={formData.loaderName} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="challanRoyaltyNumber" placeholder="Challan Royalty Number" value={formData.challanRoyaltyNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="doNumber" placeholder="DO Number" value={formData.doNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="Number" name="freight" placeholder="Freight" value={formData.freight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="qualityOfMaterials" placeholder="Quality of Materials" value={formData.qualityOfMaterials} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="tpNumber" placeholder="TP Number" value={formData.tpNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="grossWeight" placeholder="Gross Weight" value={formData.grossWeight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="tareWeight" placeholder="Tare Weight" value={formData.tareWeight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="netMaterialWeight" placeholder="Net Material Weight" value={formData.netMaterialWeight} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
             <input type="text" name="loaderMobileNumber" placeholder="Loader Mobile Number" value={formData.loaderMobileNumber} onChange={handleChange} className="mb-2 p-2 border rounded w-full" />
           </div>
        )}

       

        <div className="flex justify-between mt-4">
          <button type="button" onClick={handleSave} className="p-2 bg-green-500 text-white rounded">Save</button>
        </div>
      </form>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal fixed inset-0 flex items-center justify-center z-50">
          {/* Modal Overlay */}
          <div
            className="modal-overlay absolute inset-0 bg-black bg-opacity-50 animate-fade-in"
            onClick={() => setIsModalOpen(false)} // Close modal on overlay click
          ></div>

          {/* Modal Content */}
          <div
            className="modal-content bg-white rounded-lg p-6 z-10 
              animate-slide-up transform transition-all duration-300 ease-in-out shadow-xl"
          >
            {/* Modal Title */}
            <h2 className="text-lg font-bold text-center mb-4">
              🎉 Form Submitted Successfully! 🎉
            </h2>
            <p className="text-gray-600 text-center">
              Thank you for submitting the form.
            </p>
            <div className="flex justify-center mt-6">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditTruckLoadingForm;
