import React, { useState, useEffect } from "react";
import axios from "axios";
import { Scanner } from "@yudiel/react-qr-scanner";
import jsQR from "jsqr";

const QRScanner = () => {
  const [vehicles, setVehicles] = useState([]); // List of vehicles
  const [selectedVehicle, setSelectedVehicle] = useState(""); // Selected vehicle number
  const [qrCodes, setQrCodes] = useState([]); // QR codes for the selected vehicle
  const [scannedResults, setScannedResults] = useState([]); // Store scanned QR codes
  const [qrCodeStatus, setQrCodeStatus] = useState({}); // Track status of QR codes
  const token = localStorage.getItem("vahan-token");

  // Fetch vehicles on mount
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/truck/vehicles`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setVehicles(response.data.data);
      } catch (error) {
        console.error("❌ Error fetching vehicles:", error);
      }
    };
    fetchVehicles();
  }, []);

  // Fetch QR codes for selected vehicle
  const fetchQRCodes = async (vehicleNumber) => {
    if (!vehicleNumber) return;
    setSelectedVehicle(vehicleNumber);
    localStorage.setItem("selectedVehicle", vehicleNumber);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/truck/vehicles/${vehicleNumber}/qr-codes`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setQrCodes(response.data.data);
    } catch (error) {
      console.error("❌ Error fetching QR codes:", error);
    }
  };

  // Handle Image Upload & Scan QR from Image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, canvas.width, canvas.height);

        if (code) {
          console.log("🔍 QR Code found in image:", code.data);
          handleQrCodeScan(code.data);
        } else {
          alert("❌ No QR code found in image.");
        }
      };
      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  // Handle QR Code Scan (Camera or Image)
  const handleQrCodeScan = (scannedCode) => {
    const vehicleNumber = localStorage.getItem("selectedVehicle") || selectedVehicle;
    if (!vehicleNumber) {
      console.error("🚨 No vehicle selected. Cannot verify QR code.");
      return;
    }

    console.log("✅ Scanned QR:", scannedCode, "for vehicle:", vehicleNumber);

    // Check if scanned QR exists in valid QR codes
    const isMatched = qrCodes.some((qr) => qr.qrCode === scannedCode);
    const status = isMatched ? "correct" : "miss";

    // Store scanned QR code with status
    setScannedResults((prevResults) => [
      ...prevResults,
      { code: scannedCode, status }
    ]);

    // Show popup for match/mismatch
    alert(isMatched ? "✅ QR Code Matched!" : "❌ QR Code Mismatched!");

    // Update status in backend
    updateQrCodeStatus(scannedCode, status, vehicleNumber);

    // Check if all QR codes are matched
    checkAllQRCodesMatched();
  };

  // Update QR Code Status in Backend
  const updateQrCodeStatus = async (scannedCode, status, vehicleNumber) => {
    if (!vehicleNumber) {
      console.error("🚨 No vehicle selected. Cannot update QR code status.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/truck/qr-code/status`,
        { qrCode: scannedCode, status, vehicleNumber },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(`✅ QR Code ${status}: ${scannedCode}`);

      setQrCodeStatus((prevStatus) => ({
        ...prevStatus,
        [scannedCode]: status,
      }));
    } catch (error) {
      console.error("❌ Error updating QR code status:", error);
    }
  };

  // Check if All QR Codes are Matched
  const checkAllQRCodesMatched = () => {
    const allMatched = qrCodes.every(qr => qrCodeStatus[qr.qrCode] === "correct");

    if (allMatched) {
      alert("🚀 All QR codes matched! Marking as verified ✅");
      verifyByGuard();
    }
  };

  // API Call to Mark as Verified
  const verifyByGuard = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/truck/verify-guard`,
        { vehicleNumber: selectedVehicle, verifiedByGuard: true },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("✅ Verified by guard API called successfully!");
    } catch (error) {
      console.error("❌ Error verifying by guard:", error);
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>QR Code Scanner</h1>

      {/* Vehicle Selection Dropdown */}
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="vehicleDropdown">Select Vehicle: </label>
        <select
          id="vehicleDropdown"
          value={selectedVehicle}
          onChange={(e) => {
            const vehicleNumber = e.target.value;
            setSelectedVehicle(vehicleNumber);
            fetchQRCodes(vehicleNumber);
          }}
        >
          <option value="">-- Select --</option>
          {vehicles.map((vehicle) => (
            <option key={vehicle.vehicleNumber} value={vehicle.vehicleNumber}>
              {vehicle.vehicleNumber} - {vehicle.driverName}
            </option>
          ))}
        </select>
      </div>

      {/* Display QR Codes for Selected Vehicle */}
      {qrCodes.length > 0 && (
        <div style={{ marginBottom: "20px" }}>
          <h2>QR Codes for {selectedVehicle}:</h2>
          <ul>
            {qrCodes.map((qrCode, index) => (
              <li key={index}>
                {qrCode.qrCode}
                {qrCodeStatus[qrCode.qrCode] === "correct" ? (
                  <span style={{ color: "green", marginLeft: "10px" }}>✓</span>
                ) : qrCodeStatus[qrCode.qrCode] === "miss" ? (
                  <span style={{ color: "red", marginLeft: "10px" }}>✘</span>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* QR Scanner */}
      <Scanner
        onScan={(data) => data && handleQrCodeScan(data[0].rawValue)}
        onError={(err) => console.error("❌ Scan Error:", err)}
        style={{ width: "100%", maxWidth: "500px", height: "auto" }}
      />

      {/* Upload Image Button */}
      <div className="mt-4">
        <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginBottom: "20px" }} />
      </div>
    </div>
  );
};

export default QRScanner;
