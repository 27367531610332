import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditTruckLoadingModal = ({ isOpen, onClose, loadingId, onRefresh }) => {
  const [loadingDetails, setLoadingDetails] = useState(null);
  const [initialLoadingDetails, setInitialLoadingDetails] = useState(null); // Store original data
  const [error, setError] = useState('');

  useEffect(() => {
    if (loadingId) {
      fetchLoadingDetails();
    }
  }, [loadingId]);

  const fetchLoadingDetails = async () => {
    const token = localStorage.getItem('vahan-token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/permission/getUserById/${loadingId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const loadingData = response.data;

      if (loadingData.unloadingDateAndTime) {
        loadingData.unloadingDateAndTime = convertToIST(loadingData.unloadingDateAndTime);
      }
      setLoadingDetails(loadingData);
      setInitialLoadingDetails(loadingData); // Store original data for resetting
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching loading details');
    }
  };

  const convertToIST = (utcDate) => {
    const date = new Date(utcDate);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date.toISOString().slice(0, 16); // Format to YYYY-MM-DDTHH:MM
  };

  // Function to calculate shortage of materials using the API
  const calculateShortage = async (receivedWeight) => {
    const token = localStorage.getItem('vahan-token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/truck/truckLoading/${loadingId}/calculateShortage`,
        { receivedWeight },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { shortageOfMaterials } = response.data;
      setLoadingDetails((prevDetails) => ({
        ...prevDetails,
        shortageOfMaterials: shortageOfMaterials.toString(), // Update shortage in the form
      }));
    } catch (err) {
      setError(err.response?.data?.message || 'Error calculating shortage');
    }
  };

  const handleSave = async () => {
    const token = localStorage.getItem('vahan-token');
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/permission/updateUser/${loadingId}`, loadingDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onRefresh(); // Refresh the parent component data
      resetForm(); // Reset the form after save
      onClose(); // Close the modal
    } catch (err) {
      setError(err.response?.data?.message || 'Error updating loading details');
    }
  };

  const handleReceivedWeightChange = (e) => {
    const receivedWeight = e.target.value;
    setLoadingDetails((prevDetails) => ({
      ...prevDetails,
      receivedWeight,
    }));

    // Trigger the calculation of shortage whenever received weight changes
    if (receivedWeight) {
      calculateShortage(receivedWeight);
    }
  };
  const handleDateChange = (e) => {
    console.log('h',e.target.value)
    const selectedDate = new Date(e.target.value); // Convert the input to a Date object
    const formattedDate = formatDateToString(e.target.value); // Format the date to your required string format

    setLoadingDetails({
      ...loadingDetails,
      truckReachTime: formattedDate // Store the formatted date as a string
    });
  };

  // Function to format the date to the desired format: "27th Jan 5 PM"
  const formatDateToString = (date) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    let formattedDate = date.toLocaleString('en-US', options); // Format to 'Sat, Jan 27, 2025, 5:00 PM'

    // Replace weekday and year and keep the desired format like "27th Jan 5 PM"
    formattedDate = formattedDate.replace(/(.*), (.*) (\d{1,2}), (\d{4}), (.*)/, "$3th $2 $5");

    return formattedDate;
  };
  const resetForm = () => {
    setError(''); // Clear any error messages
  };

  const handleClose = () => {
    // Reset changes by setting back to initial loading details
    setLoadingDetails(initialLoadingDetails);
    onRefresh();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 rounded shadow-lg">
        <h2 className="text-xl font-bold">Edit Truck Loading</h2>
        {error && <p className="text-red-500">{error}</p>}
        {loadingDetails && (
          <form>
            <div>
              <label>Unloading Site</label>
              <input
                type="text"
                name="unloadingSite"
                value={loadingDetails.unloadingSite || ''}
                onChange={(e) => setLoadingDetails({ ...loadingDetails, unloadingSite: e.target.value })}
                className="mb-2 p-2 border rounded w-full"
              />
            </div>

            <div>
              <label>Received Weight</label>
              <input
                type="text"
                name="receivedWeight"
                value={loadingDetails.receivedWeight || ''}
                onChange={handleReceivedWeightChange}
                className="mb-2 p-2 border rounded w-full"
              />
            </div>

            <div>
              <label>Shortage of Materials</label>
              <input
                type="text"
                name="shortageOfMaterials"
                value={loadingDetails.shortageOfMaterials || ''}
                onChange={(e) => setLoadingDetails({ ...loadingDetails, shortageOfMaterials: e.target.value })}
                className="mb-2 p-2 border rounded w-full"
                readOnly // Make it readonly as it will be calculated automatically
              />
            </div>
            <div>
  <label>Truck Reach Time</label>
  <input
      type="datetime-local"
      name="truckReachTime"
      value={loadingDetails.truckReachTime || ''}
      onChange={handleDateChange}
      className="mb-2 p-2 border rounded w-full"
    />
  {/* <input
    type="datetime-local" // Input for date and time
    name="truckReachTime"
    value={loadingDetails.truckReachTime || ''}
    onChange={(e) =>
      setLoadingDetails({ ...loadingDetails, truckReachTime: e.target.value })
    }
    className="mb-2 p-2 border rounded w-full"
  /> */}
</div>

            <div className="w-full flex items-center justify-end gap-2">
              <button type="button" onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">
                Save
              </button>
              <button type="button" onClick={handleClose} className="bg-gray-300 px-4 py-2 rounded">
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditTruckLoadingModal;
